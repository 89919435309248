export * from "ce/PluginActionEditor/hooks/useActionSettingsConfig";
import { useActionSettingsConfig as CE_useActionSettingsConfig } from "ce/PluginActionEditor/hooks/useActionSettingsConfig";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import { useLocation } from "react-router";
import { IDE_TYPE } from "ee/entities/IDE/constants";
import type { PluginType } from "entities/Plugin";
import type { Action } from "entities/Action";
import { useWorkflowQuerySettings } from "ee/hooks/workflowHooks";
import { useModuleActionSettings } from "ee/pages/Editor/ModuleEditor/ModulePluginActionEditor/hooks/useModuleActionSettings";

function useActionSettingsConfig(action?: Action) {
  const { pathname } = useLocation();
  const IDEType = getIDETypeByUrl(pathname);

  const moduleSettingsConfig = useModuleActionSettings(action);

  const workflowSettingsConfig = useWorkflowQuerySettings({
    pluginId: action?.pluginId || "",
    pluginType: (action?.pluginType as PluginType) || "",
  });

  const appSettingsConfig = CE_useActionSettingsConfig(action);

  switch (IDEType) {
    case IDE_TYPE.None:
    case IDE_TYPE.App:
      return appSettingsConfig;
    case IDE_TYPE.Package:
      return moduleSettingsConfig;
    case IDE_TYPE.Workflow:
      return workflowSettingsConfig;
  }
}

export { useActionSettingsConfig };
